.doctor_img {
    max-width: 370px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
}

.doctor_img img {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.doctor_text {
    padding: 10px;
    text-align: center;
}
.doc-name{
    text-decoration: none;
    color: #1d3ce2;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
}

.doctor_text h3 {
    font-size: 22px;
    font-weight: 500;
    color: #1d3ce2;
    margin-bottom: 0;
}
.doctor_text p {
    font-size: 16px;
    color: #f9313b;
}
.font-weight{
    font-weight: 450;
}
.card-text {
    text-align: justify;
}

.ul_list li {
    list-style-type: none;
}

.ul_list li::before {
    content: '\00BB';
    display: inline-block;
    margin-right: 15px;
    font-size: 25px;
    font-weight: 600;
    line-height: 10px;
}

.widget-title {
    position: relative;
    padding: 19px 40px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    font-size: 35px;
    font-weight: 600;
    text-align: center;
    color: #f9313b;
}

.widget-content {
    position: relative;
}

.widget-content .image-list {
    position: relative;
    margin: 0 -5px;
    list-style: none;
}

.widget-content .image-list li {
    position: relative;
    display: inline-block;
    margin: 0 5px 10px;
}

.widget-content .image-list li figure {
    position: relative;
    display: inline-block;
}

.widget-content .image-list li figure img {
    border-radius: 8px;
    width: 70px;
}

.text-title {
    font-weight: 700;
}


@media (max-width: 768px) {
    .widget-title {
        font-size: 25px;
        padding: 11px 20px;
    }

    .widget-content .image-list {
        padding-left: 1rem;
    }
    .hero-section{
        order: 1;
    }
    .fix-list{
        font-size: 14px;
    }
    .fix-list li:last-child{
        word-spacing: -2px;
    }
    .Infrastructure-ul{
        padding-left: 0px;
    }
    .custom-style>li{
        list-style-type: square;
      }
}
/* Accordion Styles */
.accordion-button {
    background-color: #f1f1f1; /* Simple background */
    color: #000;
    border: none;
    box-shadow: none;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    border-radius: 5px; /* Adding border-radius for better look */
}

/* Remove the default arrow from the accordion */
.accordion-button::after {
    display: none; /* Completely removes the arrow */
}

.accordion-button:focus {
    box-shadow: none;
    border-color: transparent;
}

.accordion-button.collapsed {
    background-color: #f1f1f1;
}

.accordion-item {
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 10px;
}

.accordion-body {
    padding: 1rem;
    background-color: #f9f9f9;
}


/* Toggle Text Styles */
.toggle-text {
    margin-left: auto;
    font-size: 14px;
    font-weight: 400;
    color: #007bff;
    padding-left: 10px;
}

/* OPD Timings Heading */
.opd-heading {
    text-align: center;
    font-size: 24px;
    color: #f9313b;
    margin-bottom: 20px;
}


@media (min-width: 768px) and (max-width: 1024px) {
    .widget-content .image-list {
        padding-left: 0;
    }
    .custom-style>li{
      list-style: none;
      padding: 5px 30px;
      background-image: url("https://cdn.pixabay.com/photo/2016/03/31/14/37/check-mark-1292787_1280.png");
      background-repeat: no-repeat;
      background-position: left center;
      background-size: 20px;
    }
}
@media (min-width: 1024px) and (max-width: 1440px) {
    .custom-style>li{
        list-style: none;
        padding: 5px 30px;
        background-image: url("https://cdn.pixabay.com/photo/2016/03/31/14/37/check-mark-1292787_1280.png");
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 20px;
      }
}
@media (min-width: 1440px) {
    .custom-style>li{
        list-style: none;
        padding: 5px 30px;
        background-image: url("https://cdn.pixabay.com/photo/2016/03/31/14/37/check-mark-1292787_1280.png");
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 20px;
      }
}
@media (min-width: 992px) {
    .doc-card-info.col-lg-3 {
        flex: 0 0 auto;
        width: 20%;
    }
    .custom-style>li{
        list-style: none;
        padding: 5px 30px;
        background-image: url("https://cdn.pixabay.com/photo/2016/03/31/14/37/check-mark-1292787_1280.png");
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 20px;
      }
}