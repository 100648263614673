/* DrNB Page */
.drnb-neurology {
  margin: 20px;
  padding: 20px;
  margin-top: 4rem;
  background-color: aliceblue;
}

.banner {
  background: #0077b6;
  color: white;
  text-align: center;
  padding: 40px 20px;
}


.promo-image {
  text-align: center;
  margin: 20px 0;
}

.promo-image img {
  width: 73%;
  max-width: 800px;
}

.registration {
  background: #f8f9fa;
  padding: 20px;
  text-align: center;
}

.registration form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: auto;
}

.registration input,
.registration textarea {
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
}

.registration button {
  background: #0077b6;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
}

.team {
  text-align: center;
  padding: 20px;
}

.drnb-doc-text h3 {
  font-size: 15px;
  font-weight: 500;
  color: #1d3ce2;
  margin-bottom: 0;
}

.drnb-doc-text p {
  font-size: 12px;
  color: #f9313b;
  margin-bottom: 0;
}

.facilities {
  text-align: center;
  padding: 20px;
  margin-top: 2rem;
}

@media (max-width: 768px) {
  .drnb-neurology {
    margin: 0;
    margin-top: 4rem;
  }

  .registration {
    display: block !important;
  }

  .promo-image img {
    width: 100%;
  }
}

@media(min-width:992px) {
  .my-doc {
    width: 13rem !important;
  }

  .services {
    width: 20%;
  }
}